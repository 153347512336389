<template>
  <div class="сlopidogrel">

    <div class="game-wrapper"
         :class="[{ stage1: stage ===1  }, { stage2: stage ===2  }, { stage3: stage ===3  }, { stage4: stage ===4  },{ stage5: stage ===5  },{ stage6: stage ===6  }]">

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text desk blue">Добрий день!
            Чим можу допомогти?</p>

          <p class="game-wrapper__stage--text mobile blue">Добрий день!<br>
            Чим можу допомогти?</p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-clopidogrel/stage-1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text green">
            У нас призначення від лікаря для бабусі.<br>
            Oсь тримайте!
          </p>


          <div class="game-wrapper__stage--arrow-down"></div>

        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-clopidogrel/stage-2-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage small-p hover_type6" @click="nextStage(3)">
          <p>Поглянути<br>
            призначення</p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block popup stage3">
          <img src="../../assets/img/game-clopidogrel/stage-3-paper.png" alt="">

          <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
            <p>Далі</p>
          </div>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">

        <div class="game-wrapper__stage--white-block stage4">
          <p class="game-wrapper__stage--text blue">Можу Вам запропонувати Клопідогрел-Тева.<br>
            Він якраз є в наявності!</p>
          <div class="game-wrapper__stage--arrow-down"></div>

        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-clopidogrel/stage-4-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(5)">
          <p>До лікарського засобу</p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block popup custom-p">

          <div class="game-wrapper__stage--top-text">
            А чи знаєте Ви, як діє клопідогрел?
            <br>Він зменшує агрегацію тромбоцитів та запобігає їх подальшому склеюванню.
            Можна навести аналогію з розлученням тромбоцитів між собою.
          </div>

          <div class="game-wrapper__stage--bottom-text">
            Пропонуємо зіграти в мінігру! Основне завдання – змінити тромбоцит, щоб він не міг склеїтись з іншими.
            Впораєтесь?
          </div>

          <div class="game-wrapper__stage--btn-next-stage hover_type6 auto-m" @click="nextStage(6)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <ClopidogrelGame v-if="stage === 6"></ClopidogrelGame>

    </div>

    <div class="notes-wrapper" v-show="stage !== 6" :class="{ active: stage===3 || stage===5 }">
      <span>Інформація про лікарський засіб. РП №UA/11636/01/01. Для професійної діяльності медичних та фармацевтичних працівників.</span>

      <span>Клопідогрел-Тева (1 таблетка містить клопідогрелю бісульфату 97,86 мг, що еквівалентно клопідогрелю 75 мг). Характеристики: рожеві круглі двоопуклі таблетки, вкриті плівковою оболонкою, з гравіюванням «І» з одного боку. Лікувальні властивості: інгібітор агрегації тромбоцитів, крім гепарину. Найбільш частими побічними реакціями є: гематома, носова кровотеча, шлунково-кишкова кровотеча, діарея, абдомінальний біль, диспепсія, підшкірний крововилив/утворення синців.</span>

      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284</span>

      <span>CBG-UA-00825. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <img v-if="stage===1 || stage===2|| stage===4|| stage===6" class="bg" src="../../assets/img/game-clopidogrel/bg.png"
         alt="">

    <img v-else class="bg" src="../../assets/img/game-clopidogrel/bg-type-2.jpg" alt="">

  </div>
</template>

<script>
import ClopidogrelGame from '../../components/Clopidogrel-game.vue'

export default {
  name: "Clopidogrel",
  data: () => ({
    stage: 1,
  }),
  components: {
    ClopidogrelGame,
  },
  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0)
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";


.сlopidogrel {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;


    &.stage6 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;

        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;
        height: 110px;

        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          height: auto;

          img {
            max-width: 280px;

            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

        &.stage3 {
          img {
            max-width: 280px;
            width: 100%;
          }
        }
      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $EB;
        text-align: center;

        max-width: 610px;
        width: 100%;

        &.mobile {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
          font-family: $B;
        }
      }

      &--img-circle {
        max-width: 358px;
        width: 100%;
        margin-top: 40px;

        @media screen and (max-width: 425px) {
          max-width: 274px;        }

        img {
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;

          width: 100%;
          height: auto;
          display: block;

        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        background: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;

        margin-top: 50px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

          p {
            line-height: 16px !important;
          }
        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 80px 30px 40px 30px;
        text-align: center;

        font-family: $M;
        font-size: 24px;
        line-height: 120%;
        color: white;
      }

      &--bottom-text {
        color: #424242;
        font-size: 24px;
        line-height: 120%;

        font-family: $M;

        padding: 48px 30px 80px 30px;
        text-align: center;
        max-width: 530px;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .сlopidogrel {

    padding-top: 0;

    .bg {

    }

    .game-wrapper {
      padding: 32px 20px 0 20px;

      &.stage3 {
        padding-top: 12px;
      }

      &__stage {

        &--white-block {
          height: 90px;
          padding: 10px;

          &.popup {
            padding-bottom: 17px;
            height: auto;
          }
        }

        &--text {
          font-size: 18px;
          line-height: 20px;

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {

          margin-top: 65px;

          &.mr-top {
            margin-top: 0;

            img {
              margin-top: 0;
            }
          }
        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 16px;
          line-height: 120%;
          padding: 28px 20px;
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 31px 20px 38px 20px;
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}

</style>