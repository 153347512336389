<template>
  <div class="timer">{{ time | prettify }}</div>
</template>

<script>
export default {
  name: "Timer",
  props: ['time'],
  filters: {
    prettify: function (value) {
      let data = value.split(':')
      let minutes = data[0]
      let secondes = data[1]
      if (minutes < 10) {
        minutes = minutes
      }
      if (secondes < 10) {
        secondes = "0" + secondes
      }
      return minutes + ":" + secondes
    }
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.timer {
  font-size: 36px;
  line-height: 120%;
  font-family: $EB;
  color: $green;

  &.red {
    color: #C40000;
  }

  &.green {
    color: #00a03b;
  }

  &.yellow {
    color: #afcb37;
  }

  @media screen and (max-width: 600px) {
    font-size: 36px;
    line-height: 120%;
  }
}
</style>